import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/layouts/basic/basic.vue';
import Title1 from '@/components/title1/title1.vue';
import ShowPath from '@/components/showPath/showPath.vue';
import tiposNormas from '@/statics/normativas/tipoNormas.json'
import {
  Icons
} from '@/components/base';
  
@Component({
  components: {
    Layout,
    Title1,
    Icons,
    ShowPath
  }
})
export default class Normativas extends Vue {

  tiposNormas = tiposNormas

  clickItem(item: any){
    this.$router.push(`/normativas/${item.id}`);
  }

}
  